@media screen and (min-width: 700px) {
    #main-coming-soon {
        margin-top: 0;
        width: 80vw;
        height: 100vh;
        margin-left: 10vw;
        margin-right: 10vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        align-content: center;
        justify-content: center;
        flex-wrap: wrap;
        z-index: -1;
        opacity: 0;
        animation-name: fade-in;
        animation-duration: 1s;
        animation-fill-mode: forwards;
    }

    @keyframes fade-in {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    .construction-text {
        text-align: center;
        font-family: Arial, Trebuchet MS, sans-serif;
        color: white;
        font-size: calc(15px + 1vw);
        font-weight: bold;
        font-style: italic;
        white-space: nowrap;
        background-color: rgba(222, 156, 200, 0.399);
        padding: 20px;
        border-radius: 5px;
        box-shadow: 4px 4px rgba(187, 75, 163, 0.702);
    }

    #construction-bear {
        height: 50%;
    }
}

@media screen and (max-width: 700px) {
    #main-coming-soon {
        margin-top: 0;
        width: 80vw;
        height: 100vh;
        margin-left: 10vw;
        margin-right: 10vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        align-content: center;
        justify-content: center;
        flex-wrap: wrap;
        z-index: -1;
        opacity: 0;
        animation-name: fade-in;
        animation-duration: 1s;
        animation-fill-mode: forwards;
    }

    @keyframes fade-in {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    .construction-text {
        text-align: center;
        font-family: Arial, Trebuchet MS, sans-serif;
        color: white;
        font-size: calc(15px + 1vw);
        font-weight: bold;
        font-style: italic;
        background-color: rgba(222, 156, 200, 0.399);
        padding: 20px;
        border-radius: 5px;
        box-shadow: 4px 4px rgba(187, 75, 163, 0.702);
    }

    #construction-bear {
        height: 50%;
    }
}