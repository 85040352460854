footer {
    position: fixed;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box; 
    bottom: 15px;
    width: 100%;
    height: 35px;
    padding: 5px 6vw;
    background-color: rgb(212, 228, 210);
    box-shadow: 0px 5px rgba(107, 223, 89, 0.353);
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    color: rgb(239, 252, 218);
}

.copyright {
    margin: auto;
    margin-right: 1vw;
    color: white;
    padding: 3px 10px;
    border-radius: 4px;
    background-color: rgba(176, 188, 210, 0.539);
}

footer a {
    max-width: 35px;
}

#github-link {
    height: 100%;
    margin: 0;
}

#linkedin-link {
    height: 100%;
    margin: 0;
}

