@media screen and (min-width: 700px) {
    .main-skills {
        -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
        -moz-box-sizing: border-box;    /* Firefox, other Gecko */
        box-sizing: border-box; 
        /* margin-top: 90px; */
        padding: 115px 0;
        height: 100vh;
        width: 100vw;
        /* border: 2px solid #ff0000; */
        /* min-width: 500px; */
        /* display: flex; */
        flex-direction: column;
        align-items: flex-start;
        overflow-x: hidden;
        overflow-y: scroll;
        /* padding: 20px; */
        z-index: -1;
        opacity: 0;
        animation-name: fade-in;
        animation-duration: 1s;
        animation-fill-mode: forwards;
    }

    @keyframes fade-in {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    .main-skills section {
        margin-bottom: 30px;
    }

    .cards {
        /* margin: 150px 0 100px 0; */
        position: relative;
        width: 80%;
        margin: auto;
        /* display: inline-flex;
        flex-direction: column;
        align-content: flex-start; */
    }
}

@media screen and (max-width: 700px) {
    .main-skills {
        -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
        -moz-box-sizing: border-box;    /* Firefox, other Gecko */
        box-sizing: border-box; 
        /* margin-top: 90px; */
        padding: 90px 0;
        height: 100vh;
        width: 100vw;
        /* border: 2px solid #ff0000; */
        /* min-width: 500px; */
        /* display: flex; */
        flex-direction: column;
        align-items: flex-start;
        overflow-x: hidden;
        overflow-y: scroll;
        /* padding: 20px; */
        z-index: -1;
        opacity: 0;
        animation-name: fade-in;
        animation-duration: 1s;
        animation-fill-mode: forwards;
    }

    @keyframes fade-in {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    .main-skills section {
        margin-bottom: 30px;
    }

    .cards {
        /* margin: 150px 0 100px 0; */
        position: relative;
        width: 80%;
        margin: auto;
        /* display: inline-flex;
        flex-direction: column;
        align-content: flex-start; */
    }
}