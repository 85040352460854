:root {
    --raindrop-color: rgba(29, 146, 255, 0.674);
    --raindrop-width: 6px;
    --splash-length: 0.5s;
}

.hide-raindrop {
    display: none;
}

.show-raindrop {
    display: inline;
}

.raindrop {
    position: absolute;
    top: -10px;
    background-color: var(--raindrop-color);
    height: 10px;
    width: var(--raindrop-width);
    animation-name: rainfall;
    animation-duration: 1.3s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in;
}

@keyframes rainfall {
    from {
        top: 0;
    }
    to {
        top: 99%;
    }
}

.hide-particles {
    display: none;
}

.show-particles {
    display: inline;
}

.left-particle {
    position: absolute;
    background-color: var(--raindrop-color);
    top: 99%;
    height: var(--raindrop-width);
    width: var(--raindrop-width);
    animation-name: left-particle;
    animation-duration: var(--splash-length);
    animation-fill-mode: forwards;
}

@keyframes left-particle {
    from {
        top: 99%;
        transform: translateX(0);
    }
    40% {
        top: 97%;
        transform: translateX(-20px);
    }
    to {
        top: 100%;
        transform: translateX(-30px);
    }
}

.middle-particle {
    position: absolute;
    background-color: var(--raindrop-color);
    top: 99%;
    height: var(--raindrop-width);
    width: var(--raindrop-width);
    animation-name: middle-particle;
    animation-duration: var(--splash-length);
    animation-fill-mode: forwards;
    /* animation-timing-function: ease-out; */
    /* overflow: visible; */
}

@keyframes middle-particle {
    from {
        top: 99%;
    }
    40% {
        top: 95%;
    }
    to {
        top: 100%;
    }
}

.right-particle {
    position: absolute;
    background-color: var(--raindrop-color);
    top: 99%;
    height: var(--raindrop-width);
    width: var(--raindrop-width);
    animation-name: right-particle;
    animation-duration: var(--splash-length);
    animation-fill-mode: forwards;
}

@keyframes right-particle {
    from {
        top: 99%;
        transform: translateX(0);
    }
    40% {
        top: 97%;
        transform: translateX(20px);
    }
    to {
        top: 100%;
        transform: translateX(30px);
    }
}

