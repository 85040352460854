.projects {
    background-color: rgba(222, 156, 200, 0.399);
    box-shadow: 4px 4px rgba(187, 75, 163, 0.702);
    border-radius: 5px;
    color: white;
    font-family: 'Courier New', Courier, monospace;
    font-weight: bold;
    position: relative;
    margin: 0 auto;
    padding: 10px;
    width: 80%;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 100%;
    /* height: 30vh; */
    overflow: hidden;
}

#reveal-screen-on {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    border-radius: 5px;
    z-index: 5;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    font-variant: small-caps;
    font-size: calc(15px + 1vw);
}

#reveal-screen-on::before {
    content: '>>';
    font-size: small;
    animation-name: float-left-arrow;
    animation-duration: 2s;
    animation-iteration-count: infinite;
}

#reveal-screen-on::after {
    content: '<<';
    font-size: small;
    animation-name: float-right-arrow;
    animation-duration: 2s;
    animation-iteration-count: infinite;
}

@keyframes float-left-arrow {
    from {
        margin-right: 20px;
    }
    50% {
        margin-right: 0px;
    }
    to {
        margin-right: 20px;
    }
}

@keyframes float-right-arrow {
    from {
        margin-left: 20px;
    }
    50% {
        margin-left: 0px;
    }
    to {
        margin-left: 20px;
    }
}

#reveal-screen-off {
    display: none;
    transition: display 0.7s;
}

.projects-content {
    height: 100%;
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.content-blur-on {
    filter: blur(20px);
    transition: filter 0.7s;
}

.content-blur-off {
    transition: filter 0.7s;
}

#homepage-screenshot {
    /* margin: 10px; */
    position: relative;
    margin: auto 0;
    z-index: 1;
    width: 30%;
}

#website-details {
    position: relative;
    z-index: 1;
    font-size: calc(10px + 1vw);
    overflow-y: scroll;
    width: 70%;
}

#project-source-code {
    color: white;
}