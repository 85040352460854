:root {
    --content-background-shadow: rgba(23, 111, 174, 0.239);
}

.content-background {
    position: fixed;
    background-image: linear-gradient(to bottom, rgba(254, 241, 254, 0.263), rgba(209, 247, 230, 0.457));
    box-shadow: 5px 0px 50px var(--content-background-shadow), -5px 0px 50px var(--content-background-shadow);
    width: 90vw;
    height: 100vh;
    left: 0;
    right: 0;
    top: 0;
    margin: auto;
    overflow: hidden;
    z-index: -10;
}