body {
  background-image: linear-gradient(to bottom right, rgb(172, 210, 231), rgb(245, 157, 192));
  height:100vh;
  width:100vw;
  margin: 0;
  overflow: hidden;
  z-index: -999;
}




